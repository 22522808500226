import React, { useEffect, useRef } from 'react'
import 'katex/dist/katex.min.css'
import renderMathInElement from 'katex/contrib/auto-render/auto-render'
import styled from 'styled-components'
// import TeX from '@matejmazur/react-katex'
import tw from 'tailwind.macro'

const Wrapper = styled.div`
  .katex-display {
    ${ tw`text-xl md:text-3xl` }
  }
`

const MathEquation = ({ children }) => {
  const wrapper = useRef()

  useEffect(() => {
    renderMathInElement(wrapper.current, {
      delimiters: [
        { left: '$$$', right: '$$$', display: true },
        { left: '$$', right: '$$', display: false },
        { left: '\\(', right: '\\)', display: false },
        { left: '\\[', right: '\\]', display: true }
      ]
    })
  })

  return (
    <Wrapper ref={wrapper}>
      {children}
    </Wrapper>
  )
}

export default MathEquation
