import React, { useEffect } from 'react'
import styled from 'styled-components'

// Algorand's Hubspot Portal ID
const PORTAL_ID = '26119259'

const createForm = (formId, target) => {
  window.hbspt.forms.create({
    portalId: PORTAL_ID,
    formId,
    target: `#${target}`,
  })
}

const FormContainer = styled.div`
  iframe {
    width: 100% !important;
  }
`

const HubspotForm = ({ formId }) => {
  const targetId = `hubspotForm${formId}`

  useEffect(() => {
    if (window.hbspt) {
      createForm(formId, targetId)
    } else {
      const script = document.createElement('script')
      script.src = '//js-eu1.hsforms.net/forms/shell.js'
      document.body.appendChild(script)

      script.addEventListener('load', () => {
        if (window.hbspt) {
          createForm(formId, targetId)
        }
      })
    }
  })

  return (
    <FormContainer>
      <div id={targetId}></div>
    </FormContainer>
  )
}

export default HubspotForm
