export default function formatDate (aDate) {
  const date = new Date(aDate)

  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC'
  })
}
