import React from 'react'
import { graphql, Link } from 'gatsby'
import Gist from 'react-gist'
import { handlePrismicLinkField } from '../utils'
import { withPreview } from 'gatsby-source-prismic'

// components
import PageWrap from '../components/Layouts/Global/PageWrap'
import Mantel from '../components/General/Mantel'
import Button from '../components/General/Buttons/Button'
import HubspotForm from '../components/General/HubspotForm'

// utils
import formatDate from '../utils/formatDate'
// import mergePreviewData from '../utils/mergePreviewData'

// dynamic components
import WysiwygContent from '../components/General/WysiwygContent'
import ContenImage from '../components/General/ContentImage'
import BlockQuote from '../components/General/Blockquote'
import ScriptEmbed from '../components/General/ScriptEmbed'
import MathEquation from '../components/General/MathEquation'

const transformSlicesData = function (slice) {
  let data = {
    type: slice.slice_type,
  }

  switch (slice.slice_type) {
    case 'wysiwyg_field':
      data.content = slice.primary.content.html
      break
    case 'content_image':
      data.img = {
        src: slice.primary.image.url,
        alt: slice.primary.image.alt,
        ...slice.primary.image.dimensions,
      }
      data.alignment = slice.primary.alignment
      data.caption = slice.primary.caption.text
      break
    case 'block_qoute':
      data.text = slice.primary.qoute.text
      break
    case 'github_gist':
      data.id = slice.primary.gist_id
      data.file = slice.primary.gist_file
      break
    case 'script_embed':
      data.embed = slice.primary.script_embed
      break
    case 'button':
      data = {
        type: slice.slice_type,
        text: slice.primary.button_text.text,
        ...handlePrismicLinkField(slice.primary.button_url),
      }
      break
    case 'math_equation':
      data.formula = slice.primary.equation.text
      break
    case 'hubspot_embed':
      data.formId = slice.primary.form_id
      break
  }
  return data
}

const transformData = function (page) {
  const title = page.data.title.text
  const date = formatDate(page.data.date)
  return {
    seo: {
      title,
      description: page.data.meta_description,
    },
    mantelData: {
      title: page.data.article_type === 'blog' ? 'Blog' : 'News',
      crumbs: [
        {
          path: '/resources',
          text: 'Resources',
        },
        {
          path: '/resources/news',
          text: 'News',
        },
      ],
    },
    title,
    date,
    author: page.data.authors_name,
    content: page.data.body.map((item) => transformSlicesData(item)),
  }
}

const Article = ({ data }) => {
  const { prismicArticle } = data
  const { seo, mantelData, title, date, author, content } = transformData(
    prismicArticle
  )

  return (
    <PageWrap bg={1} seo={seo}>
      <Mantel {...mantelData} />
      <div className="max-w-xl mx-auto py-8 break-words">
        <p className="uppercase font-light text-sm mb-2 font-sans">{date}</p>
        <h1
          className="
            font-sans
            font-normal
            lg:text-4xl
            text-3xl
            text-charcoal
            leading-tight
          "
        >
          {title}
        </h1>
        <p className="text-sm font-light my-2 font-sans">By: {author}</p>
        {content.map((section, idx) => {
          if (section.type === 'wysiwyg_field') {
            return (
              <div key={'section' + idx} className="my-4">
                <MathEquation>
                  <WysiwygContent {...section} />
                </MathEquation>
              </div>
            )
          }
          if (section.type === 'content_image') {
            return (
              <div key={'section' + idx} className="my-4">
                <ContenImage {...section} />
              </div>
            )
          }
          if (section.type === 'block_qoute') {
            return (
              <div key={'section' + idx} className="my-4">
                <BlockQuote>{section.text}</BlockQuote>
              </div>
            )
          }
          if (section.type === 'github_gist') {
            return (
              <div key={'section' + idx} className="my-4">
                <Gist {...section}></Gist>
              </div>
            )
          }
          if (section.type === 'script_embed') {
            return (
              <div key={'section' + idx} className="my-4">
                <ScriptEmbed {...section}></ScriptEmbed>
              </div>
            )
          }
          if (section.type === 'button') {
            const LinkComponent = section.external ? 'a' : Link
            return (
              <div key={'section' + idx} className="my-4">
                <LinkComponent
                  className="no-underline"
                  to={section.route}
                  href={section.route}
                  target={section.external ? '_blank' : null}
                >
                  <Button>{section.text}</Button>
                </LinkComponent>
              </div>
            )
          }
          if (section.type === 'math_equation') {
            return (
              <div key={'section' + idx} className="my-4">
                <MathEquation formula={section.formula} />
              </div>
            )
          }
          if (section.type === 'hubspot_embed') {
            return (
              <div key={'section' + idx} className="my-4">
                <HubspotForm formId={section.formId} />
              </div>
            )
          }
        })}
      </div>
    </PageWrap>
  )
}

export default withPreview(Article)

export const pageQuery = graphql`
  query ArticleBySlug($uid: String!, $locale: String!) {
    prismicArticle(lang: { eq: $locale }, uid: { eq: $uid }) {
      uid
      data {
        article_type
        meta_description
        title {
          text
        }
        date
        excerpt {
          html
        }
        authors_name
        body {
          ... on PrismicArticleBodyContentImage {
            slice_type
            primary {
              image {
                alt
                copyright
                url
                dimensions {
                  width
                  height
                }
              }
              caption {
                text
              }
              alignment
            }
          }
          ... on PrismicArticleBodyWysiwygField {
            slice_type
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicArticleBodyBlockQoute {
            slice_type
            primary {
              qoute {
                text
              }
            }
          }
          ... on PrismicArticleBodyGithubGist {
            slice_type
            primary {
              gist_id
            }
          }
          ... on PrismicArticleBodyScriptEmbed {
            slice_type
            primary {
              script_embed
            }
          }
          ... on PrismicArticleBodyButton {
            slice_type
            primary {
              button_text {
                text
              }
              button_url {
                url
                link_type
              }
            }
          }
          ... on PrismicArticleBodyHubspotEmbed {
            slice_type
            primary {
              form_id
            }
          }
        }
      }
    }
  }
`
